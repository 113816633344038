<template>
  <section class="dashboard">

    <Loader :loading="showLoader" />
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="table-responsive mb-0 pb-3">
            <div class="card-title mb-0 border-bottom" style="height: 50px;">
              <span class="p-3  float-left">
                Banner
              </span>
              <router-link to="/add-banner">
                <a class="btn m-2 float-right btn  btn-primary">Add  New</a>
              </router-link>
            </div>
            <table class="table table-centered table-nowrap border-bottom">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Banner</th>
                  <th>Title</th>
                  <th>Sub Title</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(slider, index)  in slider" :key="slider.id">
                  <td>{{ index + 1 }}</td>
                  <td>
                    <img :src="`${imageURL}/${slider.banner}`" class="show-img img-fluid" />
                  </td>
                  <td>{{ slider.title }}</td>
                  <td>{{ slider.subtitle }}</td>
                  <td>
                    <router-link class="mr-2 table-icon" v-b-tooltip.hover title="Edit"
                      :to="`/edit-banner/${slider._id}`">
                      <i class="mdi mdi-pencil "></i>
                    </router-link>
                    <a style="cursor: pointer;" v-on:click="deleteFile(slider._id)" class="mr-2 table-icon" v-b-tooltip.hover title="Delete">
                      <i class="mdi mdi-delete"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import Loader from "../components/Loader";
import { sliderApi } from "../api";
const imageURL = process.env.VUE_APP_BASE_URL;
export default {
  name: 'banner',
  components: {
    Loader,
  },
  data() {
    return {
      slider: "",
      imageURL,
      showLoader: false,
    };
  },
  
  mounted() {
    this.getBanner();
  },
  methods: {
    async getBanner() {
      this.showLoader = true;
      const { data } = await sliderApi.slider();
      this.slider = data.data.data;
      this.showLoader = false;
    },
    deleteFile(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          const response = sliderApi.deleteSlider(id).then((response) => {
            if (response.status == 200) {
              this.getBanner();
              if (result.value) {
                this.$swal(
                  "Deleted!",
                  "Your file has been deleted.",
                  "success"
                );
              }
            }
          });
        }
      });
    },
  },
};

</script>